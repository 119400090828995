<template>
  <div class="WheelStorageWheelScene">
    <h2>{{ $route.meta.title }}</h2>
    <WheelStorageWheelContainer :token="$route.params.id" v-slot="{ wheel, status }">
      <template v-if="status === Progress.COMPLETE || status === Progress.ERROR">
        <WheelCard class="WheelStorageWheelScene__card" v-if="wheel" :data="wheel" />
        <div v-else class="WheelStorageWheelScene__noCard">
          {{ $t('wheel_storage.message.not_found') }}
        </div>
      </template>
    </WheelStorageWheelContainer>
  </div>
</template>

<script>
import Progress from '@types/Progress';
import WheelCard from '../WheelStorageScene/components/WheelStorageOverview/components/WheelCard2';
import WheelStorageWheelContainer from '@scenes/SelfserviceScene/containers/WheelStorageWheelContainer';

export default {
  name: 'WheelStorageWheelScene',

  components: {
    WheelCard,
    WheelStorageWheelContainer,
  },

  enums: {
    Progress,
  },
};
</script>

<style lang="scss" scoped>
.WheelStorageWheelScene {
  .WheelStorageWheelScene__card {
    margin: 20px auto;
  }

  .WheelStorageWheelScene__noCard {
    font-size: 18px;
    color: #aeaeae;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
</style>
