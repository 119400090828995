import { getWheelByToken } from '@services/thansen/wheelstorage';
import Progress from '@types/Progress';

export default {
  name: 'WheelStorageWheelContainer',
  data() {
    return {
      status: '',
      wheel: null,
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  methods: {},

  render() {
    return this.$scopedSlots.default({
      wheel: this.wheel,
      status: this.status,
    });
  },
  created() {
    this.status = Progress.WORKING;
    getWheelByToken(this.token)
      .then(response => {
        this.status = Progress.COMPLETE;
        if (response) {
          this.wheel = { ...response, status: Number(response.status) }; // TODO: Remove when API responds with an int
        }
      })
      .catch(errors => {
        this.status = Progress.ERROR;
      });
  },
  provide() {
    return {
      WheelStorageWheelContainer: this,
    };
  },
};
